import { Card, MasonryContainer } from "@mindprint-learning/design";

import HighlightableText from "../components/HighlightableText";
import { useAppSelector } from "../store/hooks";
import useSearch from "../hooks/useSearch";
import QRButton from "../components/QRButton";
import useTheme from "../hooks/useTheme";
import { strategies_subject } from "@mindprint-learning/api-lib";
import {
  AppRootView,
  SearchedBoosts,
  SearchedLifts,
} from "../modules/App/types";
import { TileProps } from "./TileTypes";
import useGetUrl from "../hooks/useGetUrl";

const Tile = ({ rootView, data }: TileProps) => {
  const searchText = useAppSelector((state) => state.app.searchText);
  const filteredData = useSearch(searchText, data);

  return (
    <>
      {filteredData ? (
        <MasonryContainer
          items={filteredData.map((item) => {
            return <CardContent item={item} rootView={rootView} />;
          })}
        />
      ) : (
        <>no data</>
      )}
    </>
  );
};

export default Tile;

const CardContent = ({ item, rootView }: CardContentProps) => {
  const theme = useTheme(item.subject || strategies_subject.Reading___Writing);
  const searchText = useAppSelector((state) => state.app.searchText);
  const url = useGetUrl(item?.code || "", rootView);

  return (
    <Card
      mediaUrl={
        item.media
          ? `${process.env.REACT_APP_MEDIA_ROOT}${item.code}.png`
          : undefined
      }
      theme={theme.value}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
        }}
      >
        <a
          style={{ color: theme.hex }}
          href={`${window.location}/${item.code}`}
          target="_blank"
          rel="noreferrer"
        >
          <h1>
            <HighlightableText match={searchText} value={item.title || ""} />
          </h1>
        </a>
        <HighlightableText match={searchText} value={item.subtitle || ""} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <QRButton color={theme.hex} value={item} />
        </div>
      </div>
    </Card>
  );
};

export interface CardContentProps {
  item: SearchedBoosts | SearchedLifts;
  rootView: AppRootView;
}
