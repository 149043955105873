import {
  Card,
  Container,
  Divider,
  MarkdownContainer,
  StickyContainer,
} from "@mindprint-learning/design";
import { useParams } from "react-router-dom";
import Nav from "../components/Nav";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useEffect } from "react";
import {
  clear,
  fetchAllBoosts,
  fetchAllLifts,
  setSelectedItem,
} from "../modules/App";
import useTheme from "../hooks/useTheme";
import { strategies_subject } from "@mindprint-learning/api-lib";
import { DetailProps } from "./DetailTypes";
import { AppRootView } from "../modules/App/types";
import QRButton from "../components/QRButton";

const Detail = ({ rootView }: DetailProps) => {
  const { code } = useParams();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.app.data);
  const selectedItem = useAppSelector((state) => state.app.selectedItem);

  useEffect(() => {
    const item = data?.filter((value) => value.code === code)[0];
    if (item) {
      dispatch(setSelectedItem(item));
    }
  }, [code, data]);

  useEffect(() => {
    if (rootView === AppRootView.boost) {
      dispatch(fetchAllBoosts());
    } else {
      dispatch(fetchAllLifts());
    }

    return () => {
      dispatch(clear());
    };
  }, [dispatch]);

  const theme = useTheme(
    selectedItem?.subject || strategies_subject.Reading___Writing
  );

  return (
    <>
      {data && selectedItem ? (
        <>
          <StickyContainer backgroundColor="#2c3436" primaryNav={true}>
            <Nav detailItem={selectedItem} rootView={rootView} />
          </StickyContainer>
          <Container>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ maxWidth: 720 }}>
                <Card
                  title={selectedItem.title || ""}
                  theme={theme.value}
                  removePadding={true}
                  youTubeVideoId={selectedItem.video || undefined}
                  mediaUrl={
                    selectedItem?.media
                      ? `${process.env.REACT_APP_MEDIA_ROOT}${selectedItem.media}`
                      : undefined
                  }
                >
                  <div
                    style={{
                      backgroundColor: "#fbfbfb",
                    }}
                  >
                    <Container>
                      {selectedItem.boost && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              fontSize: "0.85em",
                              gap: "0.5em",
                            }}
                          >
                            <div>
                              <b>Boost:</b>
                            </div>

                            <div
                              style={{
                                backgroundColor: theme.hex,
                                color: "#fff",
                                fontWeight: "bold",
                                fontSize: "0.85em",
                                padding: "0.25em 0.5em",
                                borderRadius: "0.25em",
                              }}
                            >
                              {selectedItem.boost.code}
                            </div>
                            <a
                              href={`${window.location.origin}/boost/${selectedItem.boost.code}`}
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: "#737373", fontWeight: 600 }}
                            >
                              <div>{selectedItem.boost.title}</div>
                            </a>
                          </div>
                          <Divider />
                        </>
                      )}
                      <div style={{ fontStyle: "italic" }}>
                        {selectedItem.subtitle}
                      </div>
                    </Container>
                  </div>

                  <Container>
                    <MarkdownContainer content={selectedItem.content || ""} />
                  </Container>
                </Card>
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "0.85em",
                fontStyle: "italic",
                padding: "4em 1em 2em 1em",
              }}
            >
              © {new Date().getFullYear()} MindPrint Learning, LLC
            </div>
          </Container>
        </>
      ) : (
        <div>loading</div>
      )}
    </>
  );
};

export default Detail;
