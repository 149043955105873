import {
  GroupedByEval,
  SearchedLifts,
  SearchedBoosts,
  AppRootView,
} from "../modules/App/types";
import { Card, CardThemes, Divider } from "@mindprint-learning/design";
import HighlightableText from "./HighlightableText";
import { useAppSelector } from "../store/hooks";
import QRButton from "./QRButton";
import useGetUrl from "../hooks/useGetUrl";

const SubjectCard = ({ data, title, theme, rootView }: SubjectCardProps) => {
  return (
    <>
      {data && (
        <Card
          title={title}
          theme={theme.value}
          removePadding={true}
          titleSizeEM={1.1}
        >
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5em",
              }}
            >
              <EvalGroup
                title={"Best Practice"}
                data={data.Best_Practice}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Social & Emotional"}
                data={data.Social__Emotional}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Stronger Verbal Reasoning"}
                data={data.Stronger_Verbal_Reasoning}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Stronger Verbal Skills"}
                data={data.Stronger_Verbal_Skills}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Stronger Visual Reasoning (Abstract & Spatial)"}
                data={data.Stronger_Visual_Reasoning__Abstract___Spatial_}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Stronger Visual Skills (Abstract & Spatial)"}
                data={data.Stronger_Visual_Skills__Abstract___Spatial_}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Support Verbal Reasoning"}
                data={data.Support_Verbal_Reasoning}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Support Verbal Skills"}
                data={data.Support_Verbal_Skills}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Support Visual Reasoning (Abstract & Spatial)"}
                data={data.Support_Visual_Reasoning__Abstract___Spatial_}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Support Visual Skills (Abstract & Spatial)"}
                data={data.Support_Visual_Skills__Abstract___Spatial_}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Support Executive Functions & Speed"}
                data={data.Support_Executive_Functions___Speed}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Support Executive Functions/Faster Speed"}
                data={data.Support_Executive_Functions_Faster_Speed}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Support Flexible Thinking"}
                data={data.Support_Flexible_Thinking}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Support Memory"}
                data={data.Support_Memory}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Support Slower Speed"}
                data={data.Support_Slower_Speed}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Support Spatial & Visual Motor"}
                data={data.Support_Spatial___Visual_Motor}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Support Test Anxiety"}
                data={data.Support_Test_Anxiety}
                theme={theme}
                rootView={rootView}
              />
              <EvalGroup
                title={"Test Taking"}
                data={data.Test_Taking}
                theme={theme}
                rootView={rootView}
              />
            </div>
          </>
        </Card>
      )}
    </>
  );
};

export default SubjectCard;

const EvalGroup = ({ title, data, theme, rootView }: EvalGroupProps) => {
  const searchText = useAppSelector((state) => state.app.searchText);

  return (
    <>
      {data && data.length > 0 && (
        <div>
          <h2
            style={{
              fontSize: "1.3em",
              fontWeight: 800,
              color: theme.hex,
              padding: "2rem 1.5rem 0em 1.5rem",
            }}
          >
            {title}
          </h2>
          <Divider />
          {data.map((value, index) => {
            const url = `${window.location.origin}/${rootView}/${value.code}`;
            return (
              <div
                key={`eval-group-item-${value.id}`}
                style={{
                  backgroundColor: index % 2 === 0 ? "#fbfbfb" : "#ffffff",
                  padding: "1.5rem 1.5rem",
                  gap: "1em",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "1em",
                    alignItems: "center",
                  }}
                >
                  <a
                    style={{ color: "#737373", fontWeight: 600 }}
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div style={{ fontSize: "1em" }}>
                      <HighlightableText
                        match={searchText}
                        value={value.title || ""}
                      />
                    </div>
                  </a>
                  <QRButton value={value} color={theme.hex} />
                </div>

                <HighlightableText
                  match={searchText}
                  value={value.subtitle || ""}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

interface EvalGroupProps {
  title: string;
  data: Array<SearchedLifts | SearchedBoosts>;
  theme: { value: CardThemes; hex: string };
  rootView: AppRootView;
}

export interface SubjectCardProps {
  data: GroupedByEval;
  title: string;
  theme: { value: CardThemes; hex: string };
  rootView: AppRootView;
}
