import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AppState, AppViews } from "./types";
import apiService from "../../services/api";

const initialState: AppState = {
  searchText: "",
  selectedItem: undefined,
  showShareModal: false,
  view: AppViews.list,
  loading: true,
  data: undefined,
};

export const fetchAllBoosts = createAsyncThunk("list/fetchBoosts", async () => {
  return (await apiService.getAllBoosts()).data;
});

export const fetchAllLifts = createAsyncThunk("list/fetchLifts", async () => {
  return (await apiService.getAllLifts()).data;
});

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    clear: () => {
      return initialState;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setShowSharedModal: (state, action) => {
      state.showShareModal = action.payload;
    },
    setAppView: (state, action) => {
      state.view = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBoosts.pending, (state) => {
        state.loading = true;
        state.data = undefined;
      })
      .addCase(fetchAllBoosts.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchAllBoosts.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchAllLifts.pending, (state) => {
        state.loading = true;
        state.data = undefined;
      })
      .addCase(fetchAllLifts.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchAllLifts.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const clear = appSlice.actions.clear;
export const setSearchText = appSlice.actions.setSearchText;
export const setSelectedItem = appSlice.actions.setSelectedItem;
export const setShowSharedModal = appSlice.actions.setShowSharedModal;
export const setAppView = appSlice.actions.setAppView;

export default appSlice.reducer;
