import { Button, ButtonSizes, ButtonTypes } from "@mindprint-learning/design";
import HighlightableText from "./HighlightableText";
import { QRButtonProps } from "./QRButton.types";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setSelectedItem, setShowSharedModal } from "../modules/App";

const QRButton = ({ color, value }: QRButtonProps) => {
  const dispatch = useAppDispatch();
  const searchText = useAppSelector((state) => state.app.searchText);

  return (
    <Button
      onClick={() => {
        dispatch(setSelectedItem(value));
        dispatch(setShowSharedModal(true));
      }}
      primary={true}
      type={ButtonTypes.neutral}
      size={ButtonSizes.tiny}
      label={
        <>
          <svg
            aria-hidden="true"
            data-prefix="fad"
            data-icon="qrcode"
            className="prefix__svg-inline--fa prefix__fa-qrcode prefix__fa-w-14"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            height={"1.5em"}
          >
            <g className="prefix__fa-group">
              <path
                className="prefix__fa-secondary"
                fill={color}
                d="M0 480h192V288H0zm64-128h64v64H64zM256 32v192h192V32zm128 128h-64V96h64zM0 224h192V32H0zM64 96h64v64H64z"
                opacity={0.4}
              />
              <path
                className="prefix__fa-primary"
                fill={color}
                d="M416 480h32v-32h-32zm-64 0h32v-32h-32zm64-192v32h-64v-32h-96v192h64v-96h32v32h96V288z"
              />
            </g>
          </svg>
          <div
            style={{
              color: color,
              fontWeight: 900,
              fontSize: "0.8em",
            }}
          >
            <HighlightableText
              match={searchText}
              value={value?.code?.toUpperCase() || ""}
            />
          </div>
        </>
      }
    />
  );
};

export default QRButton;
