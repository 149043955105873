import {
  CardThemes,
  Container,
  Modal,
  ModalTypes,
  StickyContainer,
} from "@mindprint-learning/design";
import { AppRootView, AppViews } from "../modules/App/types";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  clear,
  fetchAllBoosts,
  fetchAllLifts,
  setSelectedItem,
} from "../modules/App";

import List from "./List";
import Tile from "./Tile";
import Nav from "../components/Nav";
import { MainProps } from "./MainTypes";
import { useEffect } from "react";

const Main = ({ rootView }: MainProps) => {
  const view = useAppSelector((state) => state.app.view);
  const data = useAppSelector((state) => state.app.data);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (rootView === AppRootView.boost) {
      dispatch(fetchAllBoosts());
    } else {
      dispatch(fetchAllLifts());
    }

    return () => {
      dispatch(clear());
    };
  }, [dispatch]);

  return (
    <>
      <StickyContainer backgroundColor="#2c3436" primaryNav={true}>
        <Nav detailItem={undefined} rootView={rootView} />
      </StickyContainer>

      <Container>
        {view === AppViews.list && <List rootView={rootView} data={data} />}
        {view === AppViews.tile && <Tile rootView={rootView} data={data} />}
      </Container>
      <div
        style={{
          textAlign: "center",
          fontSize: "0.85em",
          fontStyle: "italic",
          padding: "4em 1em 2em 1em",
        }}
      >
        © {new Date().getFullYear()} MindPrint Learning, LLC
      </div>
    </>
  );
};

export default Main;
