import axios, { AxiosInstance } from "axios";
import { boosts } from "@mindprint-learning/api-lib";
import { LiftsPayload, strategies } from "../../modules/App/types";

const createAxiosInstance = (baseURL: string, _timeout: number) => {
  const instance: AxiosInstance = axios.create({
    baseURL,
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response?.status;
      if (status === 404) {
        console.error("404 Not Found");
        return Promise.reject(error);
      }

      const msg = error.response?.data?.msg || "Failed to connect to server";
      console.error(msg);
      return Promise.reject(error);
    }
  );

  return instance;
};

const api = createAxiosInstance(process.env.REACT_APP_API_ROOT || "", 30000);

const apiService = {
  getAllBoosts: () => {
    return api.get<Array<strategies>>("/boosts");
  },
  getAllLifts: () => {
    return api.get<Array<strategies>>("/lifts");
  },
};

export default apiService;
