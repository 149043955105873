import { useEffect, useState } from "react";
import { HighlightableTextProps } from "./HighlightableText.types";

const HighlightableText = ({ value, match }: HighlightableTextProps) => {
  const [displayValue, setDisplayValue] = useState(value);
  useEffect(() => {
    if (match !== "") {
      setDisplayValue(
        value.replace(
          new RegExp(match, "gi"),
          (match) => `<mark>${match}</mark>`
        )
      );
    } else {
      setDisplayValue(value);
    }
  }, [match, value]);
  return <div dangerouslySetInnerHTML={{ __html: displayValue }} />;
};

export default HighlightableText;
