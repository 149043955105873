import { Card, MasonryContainer } from "@mindprint-learning/design";

import SubjectCard from "../components/SubjectCard";
import { useAppSelector } from "../store/hooks";
import useSearch from "../hooks/useSearch";
import useGroupBySubject from "../hooks/useGroup";
import useTheme from "../hooks/useTheme";
import { strategies_subject } from "@mindprint-learning/api-lib";
import { ListProps } from "./ListTypes";
import { AppRootView } from "../modules/App/types";
import Masonry from "react-masonry-css";

const List = ({ rootView, data }: ListProps) => {
  const searchText = useAppSelector((state) => state.app.searchText);
  const filteredData = useSearch(searchText, data);
  const groupedData = useGroupBySubject(filteredData || []);

  const rwTheme = useTheme(strategies_subject.Reading___Writing);
  const mTheme = useTheme(strategies_subject.Math);
  const ssTheme = useTheme(strategies_subject.Study_Skills);
  const tpTheme = useTheme(strategies_subject.Standardized_Test_Prep);

  return (
    <>
      {data && (
        <Masonry
          data-testid="MasonryContainer"
          className="masonrycontainer"
          columnClassName={"column"}
          breakpointCols={{
            default: rootView === AppRootView.lift ? 3 : 4,
            1400: 3,
            1000: 2,
            700: 1,
          }}
        >
          <SubjectCard
            data={groupedData.readingWriting}
            title="Reading & Writing"
            theme={rwTheme}
            rootView={rootView}
          />

          <SubjectCard
            data={groupedData.math}
            title="Math"
            theme={mTheme}
            rootView={rootView}
          />

          <SubjectCard
            data={groupedData.studySkills}
            title="Study Skills"
            theme={ssTheme}
            rootView={rootView}
          />
          {rootView === AppRootView.boost && (
            <SubjectCard
              data={groupedData.testPrep}
              title="Standardized Test Prep"
              theme={tpTheme}
              rootView={rootView}
            />
          )}
        </Masonry>
      )}
    </>
  );
};

export default List;
