import { Modal } from "@mindprint-learning/design";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import useTheme from "../hooks/useTheme";
import { strategies_subject } from "@mindprint-learning/api-lib";
import { setShowSharedModal } from "../modules/App";
import { QRCodeCanvas } from "qrcode.react";
import useGetUrl from "../hooks/useGetUrl";
import { ShareModalProps } from "./ShareModalTypes";

const ShareModal = ({ rootView }: ShareModalProps) => {
  const dispatch = useAppDispatch();
  const selectedItem = useAppSelector((state) => state.app.selectedItem);
  const showShareModal = useAppSelector((state) => state.app.showShareModal);
  const theme = useTheme(
    selectedItem?.subject || strategies_subject.Reading___Writing
  );
  const url = useGetUrl(selectedItem?.code || "", rootView);

  return (
    <Modal
      show={selectedItem !== undefined && showShareModal}
      handleClose={() => dispatch(setShowSharedModal(false))}
      title={(selectedItem && selectedItem.title) || ""}
      type={theme.value}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2em",
          alignItems: "center",
        }}
      >
        <div style={{ fontSize: "0.8em", fontStyle: "italic" }}>
          Share this strategy with the following qr code
        </div>
        <QRCodeCanvas size={200} value={url} />
        <a
          style={{ fontWeight: 700, fontSize: ".8em", color: "#f27477" }}
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          {url}
        </a>
      </div>
    </Modal>
  );
};

export default ShareModal;
