import { boosts } from "@mindprint-learning/api-lib";
import Fuse from "fuse.js";
import {
  LiftsPayload,
  SearchedBoosts,
  SearchedLifts,
  strategies,
} from "../modules/App/types";

const useSearch = (
  value: string,
  data: Array<strategies> | undefined
): Array<SearchedBoosts | SearchedLifts> | undefined => {
  if (!data) return;

  if (value === "" || value.length < 3) {
    return data.map((item: boosts | LiftsPayload) => {
      return { ...item, score: 0 };
    });
  }

  const result = new Fuse(data, {
    includeScore: true,
    findAllMatches: true,
    includeMatches: true,
    minMatchCharLength: 3,
    keys: [
      { name: "title", weight: 0.75 },
      { name: "subtitle", weight: 0.75 },
      { name: "code", weight: 1.0 },
      { name: "eval", weight: 1.0 },
    ],
  })
    .search(value)
    .map((item) => {
      return { ...item.item, score: item.score || 0 };
    })
    .filter((value) => {
      if (value.score && value.score < 0.55) return value;
    })
    .sort((a, b) => (a.score < b.score ? -1 : 1));

  return result;
};

export default useSearch;
