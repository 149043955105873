import { boosts, lifts } from "@mindprint-learning/api-lib";

export interface AppState {
  searchText: string;
  selectedItem: strategies | undefined;
  showShareModal: boolean;
  view: AppViews;
  loading: boolean;
  data: Array<strategies> | undefined;
}
export interface LiftsPayload extends lifts {
  boost: boosts | undefined;
}

export interface SearchedLifts extends LiftsPayload {
  score: number;
}

export interface SearchedBoosts extends boosts {
  score: number;
}

export type strategies = boosts & LiftsPayload;

export interface GroupedByEval {
  Best_Practice: Array<SearchedBoosts | SearchedLifts>;
  Social__Emotional: Array<SearchedBoosts | SearchedLifts>;
  Stronger_Verbal_Reasoning: Array<SearchedBoosts | SearchedLifts>;
  Stronger_Verbal_Skills: Array<SearchedBoosts | SearchedLifts>;
  Stronger_Visual_Reasoning__Abstract___Spatial_: Array<
    SearchedBoosts | SearchedLifts
  >;
  Stronger_Visual_Skills__Abstract___Spatial_: Array<
    SearchedBoosts | SearchedLifts
  >;
  Support_Verbal_Reasoning: Array<SearchedBoosts | SearchedLifts>;
  Support_Verbal_Skills: Array<SearchedBoosts | SearchedLifts>;
  Support_Visual_Reasoning__Abstract___Spatial_: Array<
    SearchedBoosts | SearchedLifts
  >;
  Support_Visual_Skills__Abstract___Spatial_: Array<
    SearchedBoosts | SearchedLifts
  >;
  Support_Spatial___Visual_Motor: Array<SearchedBoosts | SearchedLifts>;
  Support_Executive_Functions___Speed: Array<SearchedBoosts | SearchedLifts>;
  Support_Executive_Functions_Faster_Speed: Array<
    SearchedBoosts | SearchedLifts
  >;
  Support_Slower_Speed: Array<SearchedBoosts | SearchedLifts>;
  Support_Flexible_Thinking: Array<SearchedBoosts | SearchedLifts>;
  Support_Memory: Array<SearchedBoosts | SearchedLifts>;
  Test_Taking: Array<SearchedBoosts | SearchedLifts>;
  Support_Test_Anxiety: Array<SearchedBoosts | SearchedLifts>;
}

export interface GroupedBySubject {
  studySkills: GroupedByEval;
  math: GroupedByEval;
  readingWriting: GroupedByEval;
  testPrep: GroupedByEval;
}

export enum AppRootView {
  boost = "boost",
  lift = "lift",
}

export enum AppViews {
  tile = "tile",
  list = "list",
}
