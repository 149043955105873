import {
  Icon,
  IconValues,
  Logo,
  SearchInput,
} from "@mindprint-learning/design";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setAppView, setSearchText } from "../modules/App";
import { AppRootView, AppViews } from "../modules/App/types";
import { boosts, lifts, strategies_subject } from "@mindprint-learning/api-lib";
import useFriendlyNames from "../hooks/useFriendlyNames";
import QRButton from "./QRButton";
import useTheme from "../hooks/useTheme";

const Nav = ({ detailItem, rootView }: NavProps) => {
  const searchText = useAppSelector((state) => state.app.searchText);
  const view = useAppSelector((state) => state.app.view);
  const friendlyNames = useFriendlyNames(detailItem);
  const theme = useTheme(
    detailItem?.subject || strategies_subject.Reading___Writing
  );
  const dispatch = useAppDispatch();

  return (
    <div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
      <a href="https://mindprintlearning.com" target="_blank" rel="noreferrer">
        <Logo responsive={true} inverted={true} />
      </a>
      <Sep />
      {rootView === AppRootView.boost && (
        <a href={`${window.location.origin}/boost`}>
          <img src="/boost-logo-gradient-2.png" style={{ width: 120 }} />
        </a>
      )}

      {rootView === AppRootView.lift && (
        <a href={`${window.location.origin}/lift`}>
          <img src="/lift-logo-gradient.png" style={{ width: 80 }} />
        </a>
      )}
      {detailItem ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
            <div>
              <div
                style={{
                  color: "#95a5a6",
                  fontSize: ".9em",
                  fontWeight: 700,
                  textAlign: "right",
                }}
              >
                {friendlyNames?.subject}
              </div>
              <div
                style={{
                  color: "#ececec",
                  fontSize: "1em",
                  fontWeight: 600,
                  textAlign: "right",
                }}
              >
                {friendlyNames?.eval}
              </div>
            </div>
            <QRButton color={theme.hex} value={detailItem} />
          </div>
        </div>
      ) : (
        <>
          <Sep />
          <SearchInput
            value={searchText}
            onChange={(value) => dispatch(setSearchText(value))}
            inverted={true}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5em",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "#fff",
                cursor: "pointer",
                opacity: view === AppViews.list ? 1 : 0.25,
              }}
              onClick={() => dispatch(setAppView(AppViews.list))}
            >
              <Icon value={IconValues.list} />
            </div>
            <div
              style={{
                height: "0.8em",
                width: 1,
                backgroundColor: "#ffffff",
                opacity: 0.25,
              }}
            />
            <div
              style={{
                color: "#fff",
                cursor: "pointer",
                opacity: view === AppViews.tile ? 1 : 0.25,
              }}
              onClick={() => dispatch(setAppView(AppViews.tile))}
            >
              <Icon value={IconValues.grid} />
            </div>
          </div>
          {/* <Sep />
          <div>
            <a
              href={rootView === AppRootView.boost ? "/lift" : "/boost"}
              target="_blank"
              rel="noreferrer"
              style={{ color: "#fff", fontWeight: 700, fontSize: "0.75em" }}
            >
              view more strategies
            </a>
          </div> */}
        </>
      )}
    </div>
  );
};

export default Nav;

const Sep = () => {
  return (
    <div
      style={{
        height: "2em",
        width: 1,
        backgroundColor: "#ffffff",
        opacity: 0.25,
      }}
    />
  );
};

export interface NavProps {
  detailItem: boosts | lifts | undefined;
  rootView: AppRootView;
}
