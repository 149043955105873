import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Detail from "./pages/Detail";
import "./App.css";
import { Page } from "@mindprint-learning/design";
import Main from "./pages/Main";
import { AppRootView } from "./modules/App/types";
import ShareModal from "./components/ShareModal";

const AppWrapper = () => {
  return (
    <BrowserRouter>
      <Page>
        <Routes>
          <Route path="" element={<Navigate replace to="/boost" />} />
          <Route
            path="boost/*"
            element={<RootViewTemplate rootView={AppRootView.boost} />}
          />

          <Route
            path="lift/*"
            element={<RootViewTemplate rootView={AppRootView.lift} />}
          />
        </Routes>
      </Page>
    </BrowserRouter>
  );
};

export default AppWrapper;

const RootViewTemplate = ({ rootView }: RootViewTemplateProps) => {
  return (
    <>
      <ShareModal rootView={rootView} />
      <Routes>
        <Route path="" element={<Main rootView={rootView} />} />
        <Route path="/:code" element={<Detail rootView={rootView} />} />
      </Routes>
    </>
  );
};

interface RootViewTemplateProps {
  rootView: AppRootView;
}
