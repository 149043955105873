import {
  boosts,
  lifts,
  strategies_eval,
  strategies_subject,
} from "@mindprint-learning/api-lib";

const useFriendlyNames = (
  item: boosts | lifts | undefined
): FriendlyNameType | undefined => {
  if (!item) return;

  let subject = "Reading & Writing";
  let ev = "Best Practice";

  switch (item.subject) {
    case strategies_subject.Math:
      subject = "Math";
      break;
    case strategies_subject.Study_Skills:
      subject = "Study Skills";
      break;
    case strategies_subject.Standardized_Test_Prep:
      subject = "Standardized Test Prep";
      break;
    default:
      subject = "Reading & Writing";
  }

  switch (item.eval?.toString()) {
    case strategies_eval.Stronger_Verbal_Reasoning:
      ev = "Stronger Verbal Reasoning";
      break;
    case strategies_eval.Social_and_Emotional:
      ev = "Social & Emotional";
      break;
    case strategies_eval.Stronger_Verbal_Skills:
      ev = "Stronger Verbal Skills";
      break;
    case strategies_eval.Stronger_Visual_Reasoning__Abstract___Spatial_:
      ev = "Stronger Visual Reasoning (Abstract & Spatial)";
      break;
    case strategies_eval.Stronger_Visual_Skills__Abstract___Spatial_:
      ev = "Stronger Visual Skills (Abstract & Spatial)";
      break;
    case strategies_eval.Support_Executive_Functions___Speed:
      ev = "Support Executive Functions & Speed";
      break;
    case strategies_eval.Support_Executive_Functions_Faster_Speed:
      ev = "Support Executive Functions/Faster Speed";
      break;
    case strategies_eval.Support_Flexible_Thinking:
      ev = "Support Flexible Thinking";
      break;
    case strategies_eval.Support_Slower_Speed:
      ev = "Support Slower Speed";
      break;
    case strategies_eval.Support_Spatial___Visual_Motor:
      ev = "Support Spatial & Visual Motor";
      break;
    case strategies_eval.Support_Test_Anxiety:
      ev = "Support Test Anxiety";
      break;
    case strategies_eval.Test_Taking:
      ev = "TestTaking";
      break;
    case strategies_eval.Support_Verbal_Reasoning:
      ev = "Support Verbal Reasoning";
      break;
    case strategies_eval.Support_Verbal_Skills:
      ev = "Support Verbal Skills";
      break;
    case strategies_eval.Support_Visual_Reasoning__Abstract___Spatial_:
      ev = "Support Visual Reasoning (Abstract & Spatial)";
      break;
    case strategies_eval.Support_Visual_Skills__Abstract___Spatial_:
      ev = "Support Visual Skills (Abstract & Spatial)";
      break;
    case strategies_eval.Support_Memory:
      ev = "Support Memory";
      break;
    default:
      ev = "Best Practice";
  }

  const result = {
    subject: subject,
    eval: ev,
  };

  return result;
};

export default useFriendlyNames;

export interface FriendlyNameType {
  subject: string;
  eval: string;
}
