import {
  SearchedBoosts,
  SearchedLifts,
  GroupedBySubject,
} from "../modules/App/types";
import {
  strategies_eval,
  strategies_subject,
} from "@mindprint-learning/api-lib";

const useGroupBySubject = (
  data: Array<SearchedBoosts | SearchedLifts>
): GroupedBySubject => {
  const groupByEval = (data: Array<SearchedBoosts | SearchedLifts>) => {
    return {
      Best_Practice: data.filter(
        (value) => value.eval === strategies_eval.Best_Practice
      ),
      Social__Emotional: data.filter(
        (value) => value.eval === strategies_eval.Social_and_Emotional
      ),
      Stronger_Verbal_Reasoning: data.filter(
        (value) => value.eval === strategies_eval.Stronger_Verbal_Reasoning
      ),
      Stronger_Verbal_Skills: data.filter(
        (value) => value.eval === strategies_eval.Stronger_Verbal_Skills
      ),
      Stronger_Visual_Reasoning__Abstract___Spatial_: data.filter(
        (value) =>
          value.eval ===
          strategies_eval.Stronger_Visual_Reasoning__Abstract___Spatial_
      ),
      Stronger_Visual_Skills__Abstract___Spatial_: data.filter(
        (value) =>
          value.eval ===
          strategies_eval.Stronger_Visual_Skills__Abstract___Spatial_
      ),
      Support_Verbal_Reasoning: data.filter(
        (value) => value.eval === strategies_eval.Support_Verbal_Reasoning
      ),
      Support_Verbal_Skills: data.filter(
        (value) => value.eval === strategies_eval.Support_Verbal_Skills
      ),
      Support_Visual_Reasoning__Abstract___Spatial_: data.filter(
        (value) =>
          value.eval ===
          strategies_eval.Support_Visual_Reasoning__Abstract___Spatial_
      ),
      Support_Visual_Skills__Abstract___Spatial_: data.filter(
        (value) =>
          value.eval ===
          strategies_eval.Support_Visual_Skills__Abstract___Spatial_
      ),
      Support_Spatial___Visual_Motor: data.filter(
        (value) => value.eval === strategies_eval.Support_Spatial___Visual_Motor
      ),
      Support_Executive_Functions___Speed: data.filter(
        (value) =>
          value.eval === strategies_eval.Support_Executive_Functions___Speed
      ),
      Support_Executive_Functions_Faster_Speed: data.filter(
        (value) =>
          value.eval ===
          strategies_eval.Support_Executive_Functions_Faster_Speed
      ),
      Support_Slower_Speed: data.filter(
        (value) => value.eval === strategies_eval.Support_Slower_Speed
      ),
      Support_Flexible_Thinking: data.filter(
        (value) => value.eval === strategies_eval.Support_Flexible_Thinking
      ),
      Support_Memory: data.filter(
        (value) => value.eval === strategies_eval.Support_Memory
      ),
      Test_Taking: data.filter(
        (value) => value.eval === strategies_eval.Test_Taking
      ),
      Support_Test_Anxiety: data.filter(
        (value) => value.eval === strategies_eval.Support_Test_Anxiety
      ),
    };
  };

  return {
    studySkills: groupByEval(
      data.filter((value) => value.subject === strategies_subject.Study_Skills)
    ),
    math: groupByEval(
      data.filter((value) => value.subject === strategies_subject.Math)
    ),
    readingWriting: groupByEval(
      data.filter(
        (value) => value.subject === strategies_subject.Reading___Writing
      )
    ),
    testPrep: groupByEval(
      data.filter(
        (value) => value.subject === strategies_subject.Standardized_Test_Prep
      )
    ),
  };
};

export default useGroupBySubject;
