import { CardThemes } from "@mindprint-learning/design";

const useTheme = (subject: string) => {
  let value: ThemeType = { value: CardThemes.reading_writing, hex: "#008dd0" };
  switch (subject) {
    case "Math":
      value = { value: CardThemes.math, hex: "#00ae4d" };
      break;
    case "Study_Skills":
      value = { value: CardThemes.study_skills, hex: "#8f53a1" };
      break;
    case "Standardized_Test_Prep":
      value = { value: CardThemes.test_prep, hex: "#f27277" };
      break;
    default:
      value = { value: CardThemes.reading_writing, hex: "#008dd0" };
  }

  return value;
};

export default useTheme;

export interface ThemeType {
  value: CardThemes;
  hex: string;
}
